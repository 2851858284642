import Vue from 'vue'
import VueRouter from 'vue-router'
import superAdminAuth from "@/middleware/superAdminAuth"
import adminAuth from "@/middleware/adminAuth"
import developerAuth from "@/middleware/developerAuth"
import partnerAuth from "@/middleware/partnerAuth"
import middlewarePipeline from "@/middleware/middlewarePipeline"
import OfferForm from "../views/OfferForm";
import SpecialOfferForm from "../views/SpecialOfferForm";
import TodayOfferForm from "../views/TodayOfferForm";

Vue.use(VueRouter)

const routeOptions = [
    {path: '*', name: '404',},
    {path: '/403', name: '403',},
    {path: '/500', name: '500',},
    {path: '/', name: 'Home', redirect: '/login'},
    {
        path: '/dashboard', 
        name: 'Dashboard', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true,
            // middleware: [
            //     superAdminAuth
            // ]
        }
    },
    {
        path: '/roles', 
        name: 'Role', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true,
            middleware: [
                developerAuth
            ]
        }
    },
    {
        path: '/create-role', 
        name: 'CreateRole', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true,
            middleware: [
                developerAuth
            ]
        }
    },
    {
        path: '/edit-role/:id', 
        name: 'EditRole', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true,
            middleware: [
                developerAuth
            ]
        }
    },
    {
        path: '/permissions', 
        name: 'Permission', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true,
            middleware: [
                developerAuth
            ]
        }
    },
    {
        path: '/categories',
        name: 'Category',
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/banners',
        name: 'Banner',
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/user-list',
        name: 'UserList',
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/partners',
        name: 'Partner',
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/packages',
        name: 'Package',
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/offers',
        name: 'Offer',
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/offer-create',
        name: 'OfferForm',
        component: OfferForm,
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/offer-edit/:id',
        name: 'OfferForm',
        component: OfferForm,
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/special-offers',
        name: 'SpecialOffer',
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/special-offer-create',
        name: 'SpecialOfferForm',
        component: SpecialOfferForm,
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/special-offer-edit/:id',
        name: 'SpecialOfferForm',
        component: SpecialOfferForm,
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/today-offers',
        name: 'TodayOffer',
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/today-offer-create',
        name: 'TodayOfferForm',
        component: TodayOfferForm,
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/today-offer-edit/:id',
        name: 'TodayOfferForm',
        component: TodayOfferForm,
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/icons',
        name: 'Icon',
        layout: "dashboard",
        meta: {
            requiresAuth: true,
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/scan-histories', 
        name: 'ScanHistory', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true, 
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/partner-scan-details', 
        name: 'PartnerScanDetails', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true, 
            middleware: [
                partnerAuth
            ]
        }
    },
    {
        path: '/brand-visit-histories', 
        name: 'BrandVisitHistory', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true, 
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/partner-visit-details', 
        name: 'BrandVisitDetails', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true, 
            middleware: [
                partnerAuth
            ]
        }
    },
    {
        path: '/partner-offer-avail-histories', 
        name: 'PartnerOfferAvailHistory', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true, 
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/offer-avail-histories', 
        name: 'OfferAvailHistory', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true, 
            middleware: [
                adminAuth
            ]
        }
    },
    {
        path: '/offer-avail-details', 
        name: 'AvailOfferDetails', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true, 
            middleware: [
                partnerAuth
            ]
        }
    },
    {
        path: '/offer-view-details', 
        name: 'OfferViewDetails', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true, 
            middleware: [
                partnerAuth
            ]
        }
    },
    {
        path: '/partner-report', 
        name: 'PartnerReport', 
        layout: "dashboard", 
        meta: {
            requiresAuth: true, 
            middleware: [
                partnerAuth
            ]
        }
    },
    {path: '/change-password', name: 'ChangePassword', layout: "dashboard", meta: {requiresAuth: true}},
    {path: '/revenue-report', name: 'RevenueReport', layout: "dashboard", meta: {requiresAuth: true}},
    {path: '/block-users', name: 'BlockUser', layout: "dashboard", meta: {requiresAuth: true}},
    {path: '/user-history', name: 'UserHistory', layout: "dashboard", meta: {requiresAuth: true}},
    {path: '/campaign-history', name: 'CampaignHistory', layout: "dashboard", meta: {requiresAuth: true}},
    {path: '/login', name: 'PartnerLogin'},
    {
        path: "/promotion",
        name: "Promotions",
        layout: "dashboard",
        meta: { requiresAuth: true },
      },
      {
        path: "/new-promotion-store",
        name: "NewPromotionUpload",
        layout: "dashboard",
        meta: { requiresAuth: true },
      },
      {
        path: "/promotion-update",
        name: "PromotionUpdate",
        layout: "dashboard",
        meta: { requiresAuth: true },
      },
]

let routes = routeOptions.map(route => {
    return {
        ...route,
        component: () => import(/* webpackChunkName: "[request]" */ `../views/${route.name}.vue`)
    }
})

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
    route.meta = route.meta || {};
    route.meta.layout = route.layout || parentLayout;

    if (route.children) {
        route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
    }
    return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
        return {
            x: 0,
            y: 0,
            behavior: 'smooth',
        }
    }
})

router.beforeEach((to, from, next) => {
    const auth = JSON.parse(window.localStorage.getItem('auth'));

    if (to.name && (to.name === 'Login') && auth && auth) {
        return next('/dashboard');
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (auth && auth) {
            if (!to.meta.middleware) {
                return next()
            }

            const middleware = to.meta.middleware;
            const context = {
                to,
                from,
                next,
                auth  //| You can also pass store as an argument
            }

            return middleware[0]({
                ...context,
                next: middlewarePipeline(context, middleware, 1)
            })
        }

        return next('/login')
    }
    next();
})

export default router
